import TYPES from '@/types';
import Vue from 'vue';

// Application
import UpdateInvestorProfileOnEconomicDependentsCommand from '@/modules/flagship/investor-profile/economic-dependents/application/commands/update-investor-profile-on-economic-dependents-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class FlagshipProfilingStepEconomicDependentsViewModel {
  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_ECONOMIC_DEPENDENTS_COMMAND)
  // eslint-disable-next-line max-len
  private readonly update_economic_dependents_command!: UpdateInvestorProfileOnEconomicDependentsCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_step_economic_dependents';

  dependent = [
    {
      id: 0,
      name: 'Menores',
      icon: 'noun-child.svg',
      value: 'child_dependent_count',
      total: 0,
    },
    {
      id: 1,
      name: 'Adultos',
      icon: 'icon_tickcircle.svg',
      value: 'elder_dependent_count',
      total: 0,
    },
    {
      id: 2,
      name: 'Mascotas',
      icon: 'noun-pet.svg',
      value: 'pet_dependent_count',
      total: 0,
    },
  ];

  private investor_profile_entity: InvestorProfileEntity = {
    id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    custom_goals: {},
    is_showed: false,
    person_id: '',
    employment_situation_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: true,
    net_monthly_expenses: 0,
    elder_dependent_count: 0,
    is_completed: true,
    child_dependent_count: 0,
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {},
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    has_retirement_fund: null,
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  incrementDependent(item: any) {
    if (this.dependent[item.id].total < 10) {
      this.dependent[item.id].total += 1;
    }
  }

  decrementDependent(item: any) {
    if (this.dependent[item.id].total > 0) {
      this.dependent[item.id].total -= 1;
    }
  }

  loadInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute(false);
      this.investor_profile_entity.id = investor_profile.id;
      this.dependent[0].total = investor_profile.child_dependent_count;
      this.dependent[1].total = investor_profile.elder_dependent_count;
      this.dependent[2].total = investor_profile.pet_dependent_count;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('message_error'),
      );
    }
  }

  updateInformation = async () => {
    try {
      this.view.$emit('loadingStep', true);
      this.investor_profile_entity.child_dependent_count = this.dependent[0].total;
      this.investor_profile_entity.elder_dependent_count = this.dependent[1].total;
      this.investor_profile_entity.pet_dependent_count = this.dependent[2].total;

      await this.update_economic_dependents_command.execute(this.investor_profile_entity);

      this.view.$emit('nextStep');

      return true;
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('message_error'),
      );
      return false;
    } finally {
      this.view.$emit('loadingStep', false);
    }
  }

  initialize = async () => {
    this.view.$emit('loadingStep', true);
    await this.loadInvestorProfile();
    this.view.$emit('loadingStep', false);
  }
}
