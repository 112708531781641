







































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import FlagshipProfilingStepEconomicDependentsViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-economic-dependents-view-model';

@Component({ name: 'FlagshipProfilingStepEconomicDependents' })
export default class FlagshipProfilingStepEconomicDependents extends Vue {
  @PropSync('isLoading', { type: Boolean, required: false, default: false })
  synced_is_loading!: boolean;

  @PropSync('backButtonText', {
    type: String,
    required: false,
    default: 'Regresar',
  })
  synced_back_button_text!: string;

  flagship_dependents_view_model = Vue.observable(
    new FlagshipProfilingStepEconomicDependentsViewModel(this),
  );

  created() {
    this.flagship_dependents_view_model.initialize();
  }
}
